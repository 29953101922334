<template>
  <div class="bgcard">
    <div class="serverlist">
      <button @click="newly(3)">+ {{ $t("lang.Addcontract") }}</button>
	  <div class="searchlist">
		  <div class="select_b" style="margin-right: 0.2rem">
		    <span style="color: #bbbbbb">{{ $t("lang.Devicestatus") }}：</span>
		    <el-select
		      style="height: 0.4rem; width: 1.5rem"
		      v-model="parsm.stat"
		      :placeholder="$t('lang.Please')"
		      popper-class="select_a"
		      @change="stauts"
		    >
		      <el-option :label="$t('lang.whole')" value=""></el-option>
		      <el-option :label="$t('lang.Underimplementation')" :value="1"></el-option>
		      <el-option :label="$t('lang.Completed')" :value="0"></el-option>
		    </el-select>
		  </div>
		  <div class="query">
		    <div class="inputname">
		      <input
		        v-model="input"
		        :placeholder="$t('lang.Pleaseinputkeywords')"
		      />
		    </div>
		    <div class="searchbtn">
		      <button @click="sear">
		        <p>{{ $t("lang.search") }}</p>
		      </button>
		    </div>
		  </div>
	  </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="customerName"
          :label="$t('lang.Nameoflessee')"
          align="center"
					 width="250"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.Targetaccountnumber')" align="center" width="250">
          <template #default="scope">
            <span v-if="scope.row.email">{{ scope.row.email }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="devNum"
          :label="$t('lang.Numberofterminals')"
          align="center"
					width="250"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.Terminalserialnumber')"
          align="center"
          width="320"
          show-overflow-tooltip
        >
          <template #default="scope">
            <div v-for="(item, index) in scope.row.device" :key="index">
              {{ item.sn |mids }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.Leasetime')"
          align="center"
          width="350"
        >
          <template #default="scope">
            <span
              >{{ scope.row.startTime | time }}{{ $t("lang.to")
              }}{{ scope.row.endTime | time }}</span
            >
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.state')" align="center" width="200">
          <template #default="scope">
            <span v-if="scope.row.state == 0">{{ $t("lang.Completed") }}</span>
            <span v-else>{{ $t("lang.Underimplementation") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left" v-if="scope.row.state == 1">
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="closemsg(scope.row, 2)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise" v-if="scope.row.state == 1">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.details")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_detail"
                  @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="detailsmsg(scope.row, 1)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              class="buttons"
              size="small"
              v-if="scope.row.state == 1"
              style="background-color: #434343"
              @click="closemsg(scope.row, 2)"
              >{{ $t("lang.edit") }}</el-button
            >
            <el-button
              class="buttons"
              size="small"
              style="background-color: #24b4de"
              @click="detailsmsg(scope.row, 1)"
              >{{ $t("lang.details") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 模态框 -->
    <el-dialog
      :title="title"
      :visible.sync="showSee"
      :show-close="false"
      width="9rem"
      custom-class="seteqcs_a"
    >
      <div class="setmsgs" v-if="sign == 1">
        <div class="basic_input">
          <label>{{ $t("lang.Contractname") }}：</label>
          <div>{{ alone.name }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Nameoflessee") }}：</label>
          <div>{{ alone.customerName }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Targetaccountnumber") }}：</label>
          <div v-if="alone.email">{{ alone.email }}</div>
          <div v-else>/</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Numberofterminals") }}：</label>
          <div>{{ alone.devNum }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Terminalserialnumber") }}：</label>
          <div class="serial">
            <div v-for="(item, index) in alone.device" :key="index">
              {{ item.sn |mids }}<br />
            </div>
          </div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.equipmentName") }}：</label>
          <div v-if="alone.device">{{ alone.device | name }}</div>
        </div>
        <!-- <div class="basic_input">
          <label>{{ $t("lang.ServerName") }}：</label>
          <div v-if="alone.serverName">{{ alone.serverName | name }}</div>
        </div> -->
        <!-- <div class="basic_input">
          <label>{{ $t("lang.ServerSerial") }}：</label>
          <div class="serial">
            <div v-for="(item, index) in alone.serverName" :key="index">
              {{ item.sn }}<br/>
            </div>
          </div>
        </div> -->
        <div class="basic_input">
          <label>{{ $t("lang.Leasetime") }}：</label>
          <div>
            {{ alone.startTime | time }} {{ $t("lang.to") }}
            {{ alone.endTime | time }}
          </div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.state") }}：</label>
          <div v-if="alone.state == 0">{{ $t("lang.Completed") }}</div>
          <div v-else>{{ $t("lang.Underimplementation") }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.contacts") }}：</label>
          <div>{{ alone.contact }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.Contactnumber") }}：</label>
          <div>{{ alone.mobile }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.fixedtelephone") }}：</label>
          <div>{{ alone.phone }}</div>
        </div>
        <div class="basic_input">
          <label>{{ $t("lang.address") }}：</label>
          <div>{{ alone.addr }}</div>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="1.5rem"
        v-else
      >
        <div style="font-size: 0.16rem; font-weight: bold">
          {{ $t("lang.Purchaseinformation") }}
        </div>
        <div class="setmsgs_a">
          <el-form-item :label="$t('lang.Contractname') + '：'" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Buyertype') + '：'" prop="type">
            <el-select v-model="ruleForm.type" :placeholder="$t('lang.Please')">
              <el-option
                :label="$t('lang.naturalperson')"
                :value="1"
              ></el-option>
              <el-option :label="$t('lang.EnterpriseV')" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('lang.Nameoflessee') + '：'"
            prop="customerName"
          >
            <el-input
              v-model="ruleForm.customerName"
              prop="customerName"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Targetaccountnumber') + '：'">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Leasetime') + '：'" prop="time">
            <el-date-picker
              v-model="ruleForm.time"
              class="SelectTime"
              type="daterange"
              :range-separator="$t('lang.to')"
              :start-placeholder="$t('lang.Startdate')"
              :end-placeholder="$t('lang.Enddate')"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <div
            class="basic_input"
            v-for="(item, index) in EquipmentList"
            :key="index"
          >
            <label>{{ $t("lang.Selectdevice") }}：</label>
            <div class="inputbox">{{ item.name }}</div>
            <el-button
              size="small"
              v-if="item.name"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deletelist(index, 1)"
            ></el-button>
            <i
              v-else
              class="el-icon-circle-plus-outline"
              style="font-size: 0.4rem"
              @click="openlist(1, index)"
            ></i>
          </div>
          <div
            class="basic_input"
            v-for="(item, index) in ServerList"
            :key="'sk' + index"
          >
            <label>{{ $t("lang.Selectserver") }}：</label>
            <div class="inputbox">{{ item.name }}</div>
            <el-button
              v-if="item.name"
              size="small"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deletelist(index, 2)"
            ></el-button>
            <i
              v-else
              class="el-icon-circle-plus-outline"
              style="font-size: 0.4rem"
              @click="openlist(2, index)"
            ></i>
          </div>
          <div class="AddEquipment">
            <div @click="AddList(1)">+{{ $t("lang.Adddevice") }}</div>
            <!-- <div @click="AddList(2)">+{{ $t("lang.AddServer") }}</div> -->
          </div>
        </div>
        <div style="font-size: 0.16rem; font-weight: bold; margin-top: 0.3rem">
          {{ $t("lang.Customerinformation") }}
        </div>
        <div class="setmsgs_a">
          <el-form-item :label="$t('lang.contacts') + '：'" prop="contact">
            <el-input v-model="ruleForm.contact"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.Contactnumber') + '：'" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.fixedtelephone') + '：'" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('lang.address') + '：'" prop="addr">
            <el-input v-model="ruleForm.addr"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div>
        <span class="dialog-footer" v-if="sign == 1">
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.return") }}</p>
          </button>
        </span>
        <span class="dialog-footer" v-else>
          <button class="button_a" @click="showSee = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button
            class="button_a"
            @click="submitForm('ruleForm')"
            v-if="sign == 2"
          >
            <p>{{ $t("lang.preservation") }}</p>
          </button>
          <button class="button_a" @click="submitForm('ruleForm')" v-else>
            <p>{{ $t("lang.submit") }}</p>
          </button>
        </span>
      </div>
      <el-dialog
        :show-close="false"
        width="9rem"
        custom-class="seteqcs_a"
        :title="judge == 1 ? $t('lang.Equipmentlist') : $t('lang.Serverlist')"
        :visible.sync="showSees"
        append-to-body
      >
        <div class="search">
          <el-input
            style="width: 2.5rem; margin-right: 0.2rem"
            v-model="value1"
            :placeholder="
              judge == 1
                ? $t('lang.equipmentnameorsn')
                : $t('lang.servernameorsn')
            "
          ></el-input>
          <el-button
            type="success"
            size="small"
            style="background-color: #438749; border-color: #438749"
            @click="cearch"
            >{{ $t("lang.search") }}</el-button
          >
        </div>
        <div class="serverfroms_a">
          <template slot="empty">
            <div class="noCart">{{ $t("lang.tableNull") }}</div>
          </template>
          <el-table
            :data="tableData_a.list"
            ref="singleTable"
            highlight-current-row
            @current-change="handleCurrentChange"
            style="width: 100%"
          >
            <template slot="empty">
              <div class="noCart">{{ $t("lang.tableNull") }}</div>
            </template>
            <el-table-column
              prop="name"
              :label="
                judge == 1 ? $t('lang.equipmentName') : $t('lang.ServerName')
              "
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="mid"
              :label="$t('lang.serialnumber')"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div
            style="display: flex; align-items: center"
            v-if="tableData_a.list"
          >
            <div class="allNum" style="background: #DDDDDD;border: #DDDDDD;">
              {{ $t("lang.total") }}: {{ tableData_a.total }}
            </div>
            <el-pagination
              background
              v-if="tableData_a.list"
              layout="prev, pager, next"
              :total="tableData_a.total"
              @current-change="handle"
              :prev-text="$t('lang.previouspage')"
              :next-text="$t('lang.nextpage')"
              :current-page.sync="parsm_a.page"
            >
            </el-pagination>
          </div>
        </div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="confirm">
            <p>{{ $t("lang.confirm") }}</p>
          </button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import * as contract from "@/api/contract.js";
import * as RegExp from "@/ui-utils/RegExp.js";
export default {
  data() {
    var mobiles = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseentercontactphone"));
      } /* else if (!RegExp.mobile.test(value)) {
        callback(new Error(this.$t("lang.Pleaseenterthecorrectcontactphone")));
      } */ else {
        callback();
      }
    };
    var name = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseenterthecontractname"));
      } else {
        callback();
      }
    };
    var customerName = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseenterleaseholder"));
      } else {
        callback();
      }
    };
    var contact = (rule, value, callback) => {
      if (value === "") {
        callback(this.$t("lang.Pleaseentercontact"));
      } else {
        callback();
      }
    };
    return {
      seenOnetext: this.$t("lang.binding"),
      seenTwotext: this.$t("lang.Unbundling"),
      seenFivetext: this.$t("lang.deletes"),
      seenOne: true,
      seenTwo: true,
      seenThree: true,
      seenFree: true,
      seenFive: true,

      input: "",
      tableData: [],
      tableData_a: [],
      parsm: {
        //获取合同列表参数
        page: 1,
        size: 10,
        type: 3,
        str: "",
		stat:''
      },
      parsm_a: {
        //获取未被租赁的设备列表参数
        page: 1,
        size: 10,
        type: 2,
        snOrName: "",
        serverOrDevice: 1,
		email:''
      },
      parsm_b: {
        //获取当前用户下已被购买的服务器列表参数
        page: 1,
        size: 10,
        snOrName: "",
      },
      showSee: false,
      title: "",
      sign: "", //判断打开哪个模态框（详情、编辑、添加）
      EquipmentList: [
        {
          name: "",
          id: "",
        },
      ],
      judge: "", //判断打开哪个列表（设备列表、服务器列表）
      index: "", //索引
      showSees: false,
      EquipmentName: "", //选择设备的名称
      EquipmentID: "", //择设备的id
      ServerName: "", //选择服务器的名称
      ServerID: "", //选择服务器的id
      ServerList: [
        {
          name: "",
          id: "",
        },
      ],
      alone: "", //详情数据
      ruleForm: {
        //添加合同参数
        name: "",
        type: "",
        customerName: "",
        email: "",
        time: "",
        contact: "",
        mobile: "",
        phone: "",
        addr: "",
      },
      rules: {
        //添加合同正则验证
        name: [
          {
            required: true,
            validator: name,
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("lang.Pleaseselectthetypeofrentdebit"),
            trigger: "blur",
          },
        ],
        customerName: [
          {
            required: true,
            validator: customerName,
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("lang.Pleaseenterthetarget"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("lang.Pleaseenterthecorrectemail"),
            trigger: ["blur", "change"],
          },
        ],
        time: [
          {
            required: true,
            message: this.$t("lang.Pleaseselecttheleasetime"),
            trigger: "change",
          },
        ],
        contact: [
          {
            required: true,
            validator: contact,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: mobiles,
            trigger: "blur",
          },
        ],
      },
      value1: "",
      billInfoID: "", //合约id
      customerID: "", //用户id
      activeIndex: "", //当前选中
      activeIind: "", //1设备 2服务器
    };
  },
  filters: {
    //过滤时间
    time(val) {
      let sk = val.split(" ");
      return sk[0];
    },
    //过滤设备名称
    name(val) {
      let namelist = [];
      val.forEach((item) => {
        namelist.push(item.name);
      });
      return namelist.join(",");
    },
		mids(val){
			let sk=''
			if(val){
				sk=val.slice(16)
			}
			return sk
		}
  },
  mounted() {
    this.getList();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
      }
    },
	//状态筛选
	stauts(){
		this.parsm.page = 1;
		this.parsm.size = 10;
		this.getList();
	},
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //获取合约列表
    async getList() {
      let res = await contract.list(this.parsm);
      console.log(res);
      if (res.data) {
        res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
        });
        this.tableData = res.data;
      }
    },
    //搜索筛选合约列表
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.str = this.input;
      this.getList();
    },
    //获取未被租赁的设备或服务器列表
    async getList_a() {
      if (this.judge == 1) {
        let res = await contract.statelist(this.parsm_a);
        console.log(res);
        if (res.data) {
          this.tableData_a = res.data;
          this.showSees = true;
        }
      } else {
        this.parsm_a.serverOrDevice = 1;
        let data = await contract.statelist(this.parsm_a);
        // let data = await contract.purchased(this.parsm_b);
        console.log(data);
        if (data.data) {
          this.tableData_a = data.data;
          this.showSees = true;
        }
      }
    },
    /** 设备列表分页页数发生改变 */
    handle(page) {
      if (this.judge == 1) {
        this.parsm_a.page = page;
      } else {
        this.parsm_b.page = page;
      }
      this.getList_a();
    },
    //添加合约
    newly(ind) {
      this.title = this.$t("lang.Addleasecontract");
      this.showSee = true;
      this.sign = ind;
      this.EquipmentList = [];
      this.ServerList = [];
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
        for (let key in this.ruleForm) {
          this.ruleForm[key] = "";
        }
      });
    },
    //输入设备名称sn筛选
    cearch() {
      if (this.judge == 1) {
        this.parsm_a.snOrName = this.value1;
      } else {
        this.parsm_b.snOrName = this.value1;
      }
      this.getList_a();
    },
    //编辑合约
    closemsg(row, ind) {
      this.billInfoID = row.billInfoID;
      this.customerID = row.customerID;
      this.ruleForm.customerName = row.customerName;
      this.ruleForm.type = row.type;
      this.ruleForm.name = row.name;
      this.ruleForm.email = row.email;
      this.ruleForm.time = [row.startTime, row.endTime];
      this.ruleForm.contact = row.contact;
      this.ruleForm.mobile = row.mobile;
      this.ruleForm.phone = row.phone;
      this.ruleForm.addr = row.addr;

      let sk = [];
      if (row.device) {
        row.device.forEach((item) => {
          sk.push({ name: item.name, id: item.id });
        });
      }
      this.EquipmentList = sk;

      let sk_a = [];
      if (row.serverName) {
        row.serverName.forEach((item) => {
          sk_a.push({ name: item.name, id: item.id });
        });
      }
      this.ServerList = sk_a;

      this.sign = ind;
      this.title = this.$t("lang.Editcontract");
      this.showSee = true;
      this.$nextTick(() => {
        this.$refs["ruleForm"].clearValidate();
      });
    },
    //合约详情
    detailsmsg(row, ind) {
      console.log(row);
      this.alone = row;
      this.sign = ind;
      this.title = this.$t("lang.Contractdetails");
      this.showSee = true;
    },
    //打开列表（设备、服务器）
    openlist(ind, index) {
      this.index = index;
      this.judge = ind;
      this.EquipmentName = "";
      this.ServerName = "";
      this.value1 = "";
      this.parsm_a.snOrName = "";
      this.parsm_b.snOrName = "";
	  if(this.ruleForm.email){
		this.parsm_a.email = this.ruleForm.email;
		this.parsm_b.email = this.ruleForm.email;
	  }
      if (ind == 1) {
        this.parsm_a.page = 1;
        this.parsm_a.serverOrDevice = 2;
        this.getList_a();
      } else {
        this.getList_a();
      }
    },
    //列表选中的内容
    handleCurrentChange(val) {
      if (val) {
        if (this.judge == 1) {
          this.EquipmentName = val.name;
          this.EquipmentID = val.id;
        } else {
          this.ServerName = val.name;
          this.ServerID = val.id;
        }
      }
    },
    //添加服务器、设备
    AddList(index) {
      this.activeIndex = "";
      this.activeIind = "";
      if (index == 1) {
        let equip = true;

        this.EquipmentList.forEach((item) => {
          if (item.name == "") {
            equip = false;
            return false;
          }
        });

        if (equip) {
          this.EquipmentList.push({
            name: "",
            id: "",
          });
        } else {
          this.$message.error(this.$t("lang.Pleaseselectthedevicefirst"));
        }
      } else {
        let serve = true;
        this.ServerList.forEach((item) => {
          if (item.name == "") {
            serve = false;
            return false;
          }
        });

        if (serve) {
          this.ServerList.push({
            name: "",
            id: "",
          });
        } else {
          this.$message.error(this.$t("lang.Pleaseselecttheserverfirst"));
        }
      }
    },
    //确认选中列表的内容
    confirm() {
      if (this.judge == 1) {
        let equip = true;
        this.EquipmentList.forEach((item) => {
          if (item.id == this.EquipmentID) {
            equip = false;
            return false;
          }
        });
        if (this.EquipmentName && equip) {
          this.EquipmentList[this.index].name = this.EquipmentName;
          this.EquipmentList[this.index].id = this.EquipmentID;
          this.$refs.singleTable.setCurrentRow();
          this.showSees = false;
        } else {
          this.$message.error(this.$t("lang.Pleaseselectadifferentdevice"));
        }
      } else {
        let serve = true;
        this.ServerList.forEach((item) => {
          if (item.id == this.ServerID) {
            serve = false;
            return false;
          }
        });
        if (this.ServerName && serve) {
          this.ServerList[this.index].name = this.ServerName;
          this.ServerList[this.index].id = this.ServerID;
          this.$refs.singleTable.setCurrentRow();
          this.showSees = false;
        } else {
          this.$message.error(this.$t("lang.Pleaseselectadifferentserver"));
        }
      }
    },
    //删除服务器、设备
    deletelist(index, ind) {
      if (ind === 1) {
        this.EquipmentList.splice(index, 1);
      }
      if (ind === 2) {
        this.ServerList.splice(index, 1);
      }
      this.activeIndex = index;
      this.activeIind = ind;
      // this.$confirm("此操作将删除该设备, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     this.lsitarsApi(index, ind);
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消删除",
      //     });
      //   });
    },
    async lsitarsApi(index, ind) {
      let parsmd = {
        id1: this.billInfoID,
        id2: "",
        id3: "",
      };
      if (ind == 1) {
        parsmd.id2 = this.EquipmentList[index].id;
        let res = await contract.editDelete(parsmd);
        if (res) {
          this.$message.success(this.$t("lang.Operations"));
          this.EquipmentList.splice(index, 1);
        }
      } else {
        parsmd.id3 = this.ServerList[index].id;
        let data = await contract.editDelete(parsmd);
        if (data) {
          this.$message.success(this.$t("lang.Operations"));
          this.ServerList.splice(index, 1);
        }
      }
    },
    //提交保存表单
    submitForm(formName) {
      let that = this;
      that.lsitarsApi(that.activeIndex, that.activeIind);
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.EquipmentList.length < 1) {
            that.$message.error(that.$t("lang.Pleaseselectatleastonedevices"));
            return false;
          }
          if (that.EquipmentList.length > 0) {
            if (that.EquipmentList[0].name == "") {
              that.$message.error(that.$t("lang.Pleaseselectatleastonedevices"));
              return false;
            }
          }
          // if (that.EquipmentList.length > 0 && that.ServerList.length == 0) {
          //   if (that.EquipmentList[0].name == "") {
          //     that.$message.error(that.$t("lang.Pleaseselectatleastonedevice"));
          //     return false;
          //   }
          // }
          // if (that.EquipmentList.length == 0 && that.ServerList.length > 0) {
          //   if (that.ServerList[0].name == "") {
          //     that.$message.error(that.$t("lang.Pleaseselectatleastonedevice"));
          //     return false;
          //   }
          // }
          let fid = [];
          let fid_a = "";
          if (that.ServerList.length > 0 && that.ServerList[0].name !== "") {
            that.ServerList.forEach((item) => {
              fid.push(item.id);
            });
            let set = new Set(fid);
            let newArr = Array.from(set);
            fid_a = newArr.join(",");
          }

          let sid = [];
          that.EquipmentList.forEach((item) => {
            sid.push(item.id);
          });
          sid.forEach((item, index) => {
            if (item == "") {
              sid.splice(index, 1);
            }
          });
          console.log(sid);
          let sets = new Set(sid);
          let newArrs = Array.from(sets);
          let sid_a = newArrs.join(",");
          console.log(that.ruleForm);
          if (that.sign == 3) {
            let parsm = {
              addr: that.ruleForm.addr,
              contact: that.ruleForm.contact,
              customerName: that.ruleForm.customerName,
              billInfoType: 3,
              devices: sid_a,
              devNum: sid.length,
              email: that.ruleForm.email,
              endTime: that.ruleForm.time[1],
              mobile: that.ruleForm.mobile,
              name: that.ruleForm.name,
              phone: that.ruleForm.phone,
              startTime: that.ruleForm.time[0],
              type: that.ruleForm.type,
            };
            if (fid_a !== "") {
              parsm.localServers = fid_a;
            }
            contract.add(parsm).then((res) => {
              console.log(res);
              that.$message.success(res.msg);
              that.showSee = false;
              that.activeIndex = "";
              that.activeIind = "";
              that.getList();
            });
          } else if (that.sign == 2) {
            let parsm = {
              addr: that.ruleForm.addr,
              contact: that.ruleForm.contact,
              customerName: that.ruleForm.customerName,
              billInfoType: 3,
              devices: sid_a,
              devNum: sid.length,
              email: that.ruleForm.email,
              endTime: that.ruleForm.time[1],
              mobile: that.ruleForm.mobile,
              name: that.ruleForm.name,
              phone: that.ruleForm.phone,
              startTime: that.ruleForm.time[0],
              type: that.ruleForm.type,
              billInfoId: that.billInfoID,
              customerId: that.customerID,
            };
            if (fid_a !== "") {
              parsm.localServers = fid_a;
            }
            contract.edit(parsm).then((res) => {
              console.log(res);
              that.$message.success(res.msg);
              that.showSee = false;
              that.activeIndex = "";
              that.activeIind = "";
              that.getList();
            });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-form-item__label {
  white-space: nowrap;
}
.el-date-editor .el-range-separator {
  width: 10% !important;
  white-space: nowrap;
}
</style>
<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}

.serverlist {
  display: flex;
  justify-content: space-between;

  > button {
    // width: 112px;
    // height: 50px;
    padding: 0.1rem 0.15rem;
    border: none;
    outline-style: none;
    background: #307b37;
    border-radius: 0.1rem;
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
}

.operation {
  display: flex;
}

.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

/* 合约详情模态框 */
.setmsgs {
  .basic_input:first-child {
    margin-top: 0;
  }

  .basic_input {
    margin-top: 0.2rem;
    display: flex;

    label {
      max-width: 1.6rem;
      min-width: 1.1rem;
      font-size: 0.15rem;
      font-weight: bold;
      color: #666666;
      text-align: right;
      white-space: nowrap;
      margin-right: 0.15rem;
    }

    div {
      font-size: 0.16rem;
      color: #333333;
    }

    .serial {
      div:first-child {
        margin-top: 0;
      }

      div {
        margin-top: 0.1rem;
      }
    }
  }
}

/* 添加、编辑模态框 */
.setmsgs_a {
  margin-top: 0.2rem;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .el-input,
  .el-select,
  .SelectTime {
    width: 2.4rem;
  }

  .basic_input {
    height: 0.4rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.22rem;

    label {
      width: 1.4rem;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 0.14rem;
      color: #606266;
      line-height: 0.4rem;
      padding: 0 0.12rem 0 0;
      box-sizing: border-box;
    }

    .inputbox {
      width: 1.9rem;
      height: 0.4rem;
      padding: 0 0.15rem;
      box-sizing: border-box;
      line-height: 0.4rem;
      border: 0.01rem solid #d5d5d5;
      background-color: #fff;
      border-radius: 0.04rem;
      margin-right: 0.1rem;
      margin-left: 0.22rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .el-input,
    .el-select,
    .SelectTime {
      width: 2.5rem;
    }
  }

  .AddEquipment {
    width: 3.5rem;
    height: 0.4rem;
    margin-bottom: 0.22rem;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;

    div {
      width: 1.14rem;
      height: 0.36rem;
      border: 0.02rem solid #409eff;
      color: #fff;
      font-size: 0.15rem;
      text-align: center;
      line-height: 0.36rem;
      border-radius: 0.04rem;
      background-color: #409eff;
    }
  }
  .search {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }
}

.button_a {
  border: none;
  outline-style: none;
  margin-top: 0.36rem;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 1px solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}

.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
/deep/ .current-row{
	td{
		background-color: #e1f5ff !important;
	}
}
/deep/ .is-leaf{
	border-bottom:none !important;
}
.bottom_tabes{
	display: flex;
	flex-flow: row;
	justify-content: flex-end !important;
	margin-right: 30%;
	align-items: center;
}
</style>
